import { Loading, LoadingIndicator, LoadingMessage } from '@rchitected/ui';
import React from 'react';

export const LoadingPage: React.FC<React.PropsWithChildren> = () => (
  <Loading className="flex flex-1 flex-col justify-center" visible>
    <LoadingMessage center>
      <LoadingIndicator className="h-6 w-6" />
    </LoadingMessage>
  </Loading>
);
