import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const Card: React.FC<React.PropsWithChildren<Props>> = ({ className, ...props }) => (
  <div {...props} className={classNames('rounded-xl border border-gray-200 bg-white', className)} />
);
