import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { urls } from '@/utils/url';

import { LoginPage } from '@/pages/LoginPage';
import { RegisterPage } from '@/pages/RegisterPage';

export const PublicRoutes: React.FC = () => (
  <Routes>
    <Route element={<LoginPage />} path={urls.login} />
    <Route element={<RegisterPage />} path={urls.register} />
    <Route element={<Navigate replace to={urls.login} />} path="*" />
  </Routes>
);
