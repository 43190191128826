import { translations } from '@rchitected/locale';
import { ApiErrorResult, isApiError } from '@rchitected/utility';
import { AxiosError } from 'axios';
import React from 'react';

import { FormattedValidationMessage } from './form/FormattedValidationMessage';

interface Props {
  error?: Error;
  className?: string;
}

const getMessageId = (error: AxiosError<ApiErrorResult>) => {
  return error?.response?.data.key
    ? translations.errors.api[error?.response?.data.key] ?? translations.errors.unhandled
    : translations.errors.unhandled;
};

export const SubmitError: React.FC<Props> = ({ error, className }) => {
  if (!error) return null;

  const id = isApiError(error) ? getMessageId(error) : translations.errors.unhandled;

  return <FormattedValidationMessage {...{ id, className }} values={{}} visible />;
};
