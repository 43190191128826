import classNames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from '../As';

const styles = {
  primary:
    'border-brand bg-gray-900 text-white :not:disabled:hover:bg-slate-900 disabled:bg-gray-200 disabled:border-transparent disabled:text-gray-500'
};

export type Appearance = keyof typeof styles;

interface Props {
  appearance?: Appearance;
}

export const Button = React.forwardRef(
  <T extends AsComponent>(
    // @ts-ignore
    { as = 'button', appearance, className, ...props }: Props & AsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => (
    <As
      {...{ as, ref }}
      className={classNames(
        'inline-flex items-center justify-center rounded-md border border-solid text-sm font-semibold',
        !!appearance && styles[appearance],
        className
      )}
      {...props}
    />
  )
);
