import { translations } from '@rchitected/locale';
import { Form, InputWithLabel, LoadingButton, SubmitError, ValidatedField } from '@rchitected/ui';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';

import { Logo } from '@/components/Logo';

const schema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.email.label),
    password: yup.string().required().label(translations.fields.password.label)
  })
  .required();

export const LoginPage: React.FC<React.PropsWithChildren> = () => {
  const { login } = Auth.useContainer();
  const intl = useIntl();

  const onSubmit = React.useCallback(async ({ email, password }: yup.InferType<typeof schema>) => {
    await login({ email, password });
  }, []);

  return (
    <div className="flex flex-1 flex-col items-center justify-center p-4">
      <div className="flex w-full max-w-md flex-col gap-4">
        <div className="flex justify-center p-4">
          <Logo className="inline-flex" />
        </div>

        <div className="flex flex-col gap-4 rounded-lg bg-white p-6 shadow">
          <h1 className="text-sm font-semibold">
            <FormattedMessage id={translations.pages.login.title} />
          </h1>

          <div className="text-sm text-gray-400">
            <FormattedMessage id={translations.pages.login.message} />
          </div>

          <Form {...{ schema, onSubmit }}>
            {({ submitting, submitError, handleSubmit }) => (
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <ValidatedField
                  field={InputWithLabel}
                  id="email"
                  label={<FormattedMessage id={translations.fields.email.label} />}
                  name="email"
                  placeholder={intl.formatMessage({ id: translations.fields.email.placeholder })}
                  readOnly={!!submitting}
                />

                <ValidatedField
                  field={InputWithLabel}
                  id="password"
                  label={<FormattedMessage id={translations.fields.password.label} />}
                  name="password"
                  placeholder={intl.formatMessage({ id: translations.fields.password.placeholder })}
                  readOnly={!!submitting}
                />

                <SubmitError error={submitError} />

                <LoadingButton type="submit" appearance="primary" className="h-10 w-full md:h-14" loading={submitting}>
                  <FormattedMessage id={translations.pages.login.login} />
                </LoadingButton>
              </form>
            )}
          </Form>

          <div className="text-center text-sm">
            <FormattedMessage
              id={translations.pages.login.register}
              values={{
                a: (children) => (
                  <Link className="font-semibold hover:underline" to={urls.register}>
                    {children}
                  </Link>
                )
              }}
            />
          </div>
        </div>

        <div className="text-center text-sm">
          <FormattedMessage
            id={translations.pages.login.legal}
            values={{
              tos: (children) => (
                <a className="font-semibold hover:underline" href="#" rel="noreferrer" target="_blank">
                  {children}
                </a>
              ),
              pp: (children) => (
                <a className="font-semibold hover:underline" href="#" rel="noreferrer" target="_blank">
                  {children}
                </a>
              )
            }}
          />
        </div>
      </div>
    </div>
  );
};
