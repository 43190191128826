import axios, { AxiosError } from 'axios';

export interface ApiErrorResult {
  code: number;
  key: ApiErrorCode;
  message?: string;
}

export enum ApiErrorCode {
  InvalidRequest = 'invalid_request'
}

export const isApiError = (error: unknown): error is AxiosError<ApiErrorResult> => {
  return axios.isAxiosError(error) && !!(error.response?.data as ApiErrorResult)?.['key'];
};

interface ApiErrorHandler {
  (result: ApiErrorResult): void;
}

interface ApiErrorHandlerOptions {
  status?: Record<number, ApiErrorHandler>;
  code?: Record<ApiErrorCode, ApiErrorHandler>;
  fallback?: ApiErrorHandler;
}

export const handleApiError = (error: Error, handler: ApiErrorHandlerOptions) => {
  if (!isApiError(error)) throw error;

  const response = error.response!;
  const result = response.data;

  if (handler.code?.[result.key]) return handler.code[result.key](result);
  if (handler.status?.[response.status]) return handler.status[response.status](result);
  if (handler.fallback) return handler.fallback(result);

  throw error;
};
