import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const Page: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <main className={classNames('flex flex-col gap-8 p-8', props.className)} {...props} />
);
