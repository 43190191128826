import axios, { AxiosRequestConfig } from 'axios';
import { ApiErrorCode, isApiError } from './apiError';

type RenewCallback = () => Promise<string>;

interface ApiAuthState {
  accessToken?: string;
  renewCallback?: RenewCallback;
  promise?: ReturnType<RenewCallback>;
}

const state: ApiAuthState = {};

export const getAccessToken = async () => {
  if (!state.accessToken) throw new Error('No access token defined.');

  return state.accessToken;
};

export const updateApiAccessToken = (accessToken: string | undefined, renewCallback: RenewCallback) => {
  state.accessToken = accessToken;
  state.renewCallback = renewCallback;
};

const renewAccessToken = async () => {
  if (state.promise) return state.promise;

  if (!state.renewCallback) throw new Error('Unable to renew access token.');

  const renewCallback = state.renewCallback;

  state.promise = renewCallback()
    .then((accessToken) => {
      updateApiAccessToken(accessToken, renewCallback);
      return accessToken;
    })
    .finally(() => {
      state.promise = undefined;
    });

  return state.promise;
};

export interface ApiRequestConfig extends AxiosRequestConfig {
  retryAttempt?: number;
}

export const renewAccessTokenInterceptor = async (error: Error) => {
  if (!isApiError(error)) throw error;

  if (error.response?.status !== 403) throw error;

  if (error.response?.data.key !== ApiErrorCode.InvalidRequest) throw error;

  const config: ApiRequestConfig = error.config;

  if (config.retryAttempt) throw error;

  config.retryAttempt = (config.retryAttempt ?? 0) + 1;

  const accessToken = await renewAccessToken();

  config.headers = { ...config.headers, Authorization: `Bearer ${accessToken}` };

  return axios(config);
};
