import React from 'react';
import { useAsyncFn } from 'react-use';

import { LoadingButton } from './LoadingButton';

type LoadingButtonProps = React.ComponentProps<typeof LoadingButton>;

interface Props extends Omit<LoadingButtonProps, 'loading' | 'children'> {
  children: ((loaded: boolean) => React.ReactNode) | React.ReactNode;
  once?: boolean;
  onClick(): Promise<unknown>;
}

export const AsyncButton = React.forwardRef(function AsyncButton({ once, ...props }: Props, ref: React.Ref<any>) {
  const [{ value: loaded = false, loading }, onClick] = useAsyncFn(async () => {
    await props.onClick();
    return true;
  }, [props.onClick]);

  const disabled = props.disabled || (!!loaded && !!once);
  const children = typeof props.children === 'function' ? props.children(loaded) : props.children;

  return <LoadingButton {...props} {...{ ref, loading, disabled, children, onClick }} />;
});
