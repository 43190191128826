import { PlusSmallIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';

interface HeadingProps {
  className?: string;
}

export const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = (props) => (
  <div {...props} className={classNames('flex flex-wrap items-center gap-6 sm:flex-nowrap', props.className)} />
);

export const HeadingTitle: React.FC<React.PropsWithChildren<HeadingProps>> = (props) => (
  <h1 {...props} className={classNames('text-base font-semibold leading-7 text-gray-900', props.className)} />
);

export const HeadingDetails: React.FC<React.PropsWithChildren<HeadingProps>> = (props) => (
  <div
    {...props}
    className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7"
  />
);

export const HeadingFilters: React.FC = () => (
  <HeadingDetails className="">
    <a href="#" className="text-indigo-600">
      Last 7 days
    </a>
    <a href="#" className="text-gray-700">
      Last 30 days
    </a>
    <a href="#" className="text-gray-700">
      All-time
    </a>
  </HeadingDetails>
);

export const HeadingActions: React.FC<React.PropsWithChildren> = () => (
  <a
    href="#"
    className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  >
    <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
    New invoice
  </a>
);
