import classNames from 'classnames';
import React from 'react';

interface Props {
  name?: string;
  image?: string;
  className?: string;
}

export const ProjectDetails: React.FC<React.PropsWithChildren<Props>> = ({ name, image, className, children }) => (
  <div className={classNames('flex items-center gap-x-4', className)}>
    <img
      alt={name}
      className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
      src={image}
    />
    <div className="text-sm font-medium leading-6 text-gray-900">{name}</div>
    <div className="relative ml-auto">{children}</div>
  </div>
);
