import React from 'react';

import { Auth } from '@/containers/useAuth';

import { LoadingPage } from '@/pages/LoadingPage';

import { Button } from '@rchitected/ui';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

export const App: React.FC = () => {
  const { authenticated, loading, logout } = Auth.useContainer();

  if (loading) return <LoadingPage />;

  if (!authenticated) return <PublicRoutes />;

  return (
    <div>
      <header className="">
        <Button className="absolute right-4 top-4 h-10 px-4" onClick={() => logout().catch(() => void 0)}>
          Logout
        </Button>
      </header>

      <PrivateRoutes />
    </div>
  );
};
